<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <b-loading :is-full-page="true" :active="!content"/>

            <div class="level-item">
                <h1 class="title is-1 page-title">COVERED2GO TRAVEL INSURANCE FAQ's</h1>
            </div>
            <div class="box b-shdw-3 box-content">

                <!-- <p class="has-text-centered">We've put together simple answers to questions frequently asked about our travel insurance.</p>
                <br> -->

                <!-- Top categories links -->
                <p class="title is-4 text-c2g-blue">Categories</p>
                <ul id="categories">
                    <li v-for="(s, k) in sections" :key="s.id + 'cat'" >
                        <a href="javascript:" @click="$scrollTo('#' + k)">{{ k }}</a>
                    </li>
                </ul>

                <!-- Each section -->
                <div v-for="(s, k) in sections" :key="s.id + 'sad'">
                    <p class="title is-4 s-header" :id="k">{{ k }}</p>

                    <!-- Each question -->
                    <div v-for="i in s" :key="i+'sct'">
                        <!-- Card -->
                        <b-collapse class="card" :open="i === 34">
                            <!-- Card Header -->
                            <div slot="trigger" slot-scope="props" class="card-header" role="button">
                                <p class="card-header-title" v-html="content[`q${i}`]"/>
                                <a class="card-header-icon">
                                    READ MORE... <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
                                </a>
                            </div>
                            <!-- Card body -->
                            <div class="card-content">
                                <div v-html="content[`a${i}`]"/>
                            </div>
                        </b-collapse>
                    </div>

                </div>

            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "Faqs",
        data() {
            return {
                sections: {
                    'Covid-19 Travel Insurance FAQs (Policies Issued On or After 14 October 2021)' : [ 51 ],
                    'Covid-19 Travel Insurance FAQs (Policies Issued On or After 01 June 2021 but Before 17:00 hours on 7th July 2021)' : [ 49, 50],
                    'Covid-19 Travel Insurance FAQs (Policies Issued Before 29 August 2020)': [ 35, 36 ],
                    'General Covid-19 Travel Insurance FAQs (Policies Issued On Or After 29 August 2020 but Before 01 June 2021)': [ 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48],
                    'Medical health & emergencies abroad': [ 1, 2 ],
                    'Buying your policy': [ 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 19, 20, 21, 22, 23 ],
                    'Winter Sports Cover, Sports & Activities': [ 24, 25 ],
                    'Policy Admin, Lost Docs & Changes': [ 26, 27, 28 ],
                    'Claims': [ 29 ],
                }
            }
        },
        mixins: [ contentAPI, page ],
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 20px 0 40px 0;
        text-align: center;
    }
    .s-header {
        color: $c2g_orange;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .box-content {
        padding: 40px 50px;
    }

    /deep/ {
        li {
            margin-left: 25px;
            margin-bottom: 10px;
            position: relative;
            list-style-type: none;
        }
        li:before {
            content: "";
            position: absolute;
            top: 3px;
            left: -25px;
            width: 20px;
            height: 20px;
            background-image: url('../assets/images/icons/bullet-tick-sm.png');
        }
        ul {
            margin-top: 10px;
            margin-bottom: 15px;
        }
        .card-header-icon {
            min-width: 200px;
        }
        @media screen and (max-width: $mobile_width) {
            .card-header-icon {
                display: none;
            }
        }
        .card {
            margin-bottom: 10px;
        }
    }

</style>